.nav-bar-container {
  position: sticky;
  top: 0;
  z-index: 100;
}

.mini-nav-bar {
  width: 100%;
  height: 33px;

  margin-top: -2px;
  background-color: var(--primary-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: var(--primary-color) 5px solid;

  z-index: 1;
}

.nav-bar {
  width: 100%;
  min-height: 80px;
  background-color: var(--tertiary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10vw;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.nav-bar .logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
  z-index: 100;
}

.nav-bar .nav-links {
  /* border: 1px green dotted; */
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
  padding-right: 0;
  gap: 15%;
}

.nav-bar .nav-links a {
  color: var(--secondary-color);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
}

.nav-bar .nav-links .open {
  display: none;
}

.login-btn,
.logout-btn {
  border: none;
  background-color: transparent;
  border-radius: 5px;
  font-size: 1rem;
  text-decoration: none;
  color: var(--secondary-color);
  cursor: pointer;
}

.btn-hide {
  display: none;
}

.nav-bar .nav-links .profile-pic {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--primary-color);

  /* create background color with slight transparency */
  background-color: rgba(0, 0, 0, 0.268);
}

.nav-bar .nav-links .profile-pic:hover {
  cursor: pointer;
  /* border: 3px dotted var(--primary-color); */
}


@media screen and (min-width: 730px) and (max-width: 850px) {
  .nav-bar {
    padding: 0 10vw;
  }

  .nav-bar .nav-links a {
    font-size: 1rem;
  }

  .nav-bar .logo img {
    /* adjust width using clamp */
    width: clamp(300px, 10vw, 500px);
  }

  .mini-nav-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10vw;
  }

  .mini-nav-bar .mini-nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    gap: 50px;
  }

  .mini-nav-bar .mini-nav-links a {
    color: var(--tertiary-color);
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 600;
  }

  /* use clamp to adjust the size of the logo (image) */
}

@media screen and (max-width: 730px) {
  .nav-bar .nav-links :not(.open) {
    display: none;
  }

  .nav-bar .nav-links .open {
    display: block;
  }

  .nav-bar .logo img {
    width: 300px;
  }
}

@media screen and (max-width: 450px) {
  .nav-bar .logo img {
    width: 250px;
  }
}