:root {
  /* set colors */
  --primary-color: #104484;
  --secondary-color: #2d2d2d;
  --tertiary-color: #ffffff;
}

::placeholder,
::-moz-placeholder,
::-webkit-input-placeholder {
  color: var(secondary-color);
}

.search {

  margin-bottom: 150px;
}

.search .search-filters {
  /* padding: 20px 20px 0px 20px; */
  padding: 5px;
  padding-bottom: 0px;
  border-radius: 5px;

}

.search .search-filters ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  color: black;
}

.search .search-filters ul li {
  font-weight: 600;
  color: var(--secondary-color);
  background: rgba(255, 255, 255, 0.877);
  padding: 5px;

  border: none;
  text-align: center;

  font-size: 1rem;
  margin: 0 2px;
  flex: 1;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.2s ease-in-out;
}

.search .search-filters ul li:hover {
  /* translate up a little bit  */
  transform: translateY(-2px);
  /* add a little bit of shadow */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 4px 8px 0px;
  border: none;
  border-radius: 5px;
  background-color: var(--tertiary-color);
  /* set smooth transition time and speed */
  transition: all 0.2s ease-in-out;

  /* make it smoth when unhovering */
}

.search-inputs input {
  width: 64vw;
  max-width: 800px;
  height: 60px;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 1.5rem;
  outline: none;
}

.search-results {
  width: 64vw;
  max-width: 800px;
  position: absolute;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.search-results::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.search-results ul {
  width: 100%;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.853);
  border-radius: 5px;
  padding: 0;
  margin: 0;
}

.search-results li {
  color: var(--secondary-color);
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #dcdcdc5c;
}

.search-results li .search-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.search-results li .search-text .name {
  width: 400px;
  text-overflow: wrap;
}

.search-results li .search-text .other {
  background-color: var(--tertiary-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 0.8rem;
  color: var(--secondary-color);
  text-align: right;
  width: fit-content;
}


@media screen and (max-width: 830px) {
  .search .search-filters ul li {
    font-size: 0.8rem;
  }
}


