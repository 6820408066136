.review-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 30px;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;

  justify-content: space-between;
}

.review-card .review-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.review-card .review-text .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  overflow-wrap: anywhere;
  width: 100%;
}

.review-card .review-text .info .text-review {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-wrap: anywhere;
  width: 100%;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.learn-more {
  color: rgba(99, 99, 99, 0.287);
  border: rgba(99, 99, 99, 0.287) 2px solid;
  border-radius: 50%;

  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
}

.review-card .header-container .title {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-wrap: anywhere;
}

.review-card .header-container .title .details {
  background-color: var(--tertiary-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary-color);
  text-align: left;
  width: fit-content;
}

.review-card:hover {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
  transform: scale(1.05);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.criteria {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.criterion {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.criterion .bar {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  border: none;
  border-radius: 5px;
  background-color: rgba(99, 99, 99, 0.12);
}

.criterion .bar .bar-fill {
  background-color: var(--primary-color);
  border-radius: 5px;
  height: 10px;
  filter: opacity(0.8);
}

.label-desc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-desc .label {
  font-size: 0.8rem;
}

