.review-rules-card {
  display: none;

  width: 80%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  padding: 20px 5%;
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  gap: 10px;
  max-width: 670px;
  max-height: 80vh;
  overflow-y: scroll;


  margin: auto auto;
  position: absolute;

  background: white;
  top: 140px;


  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
}

.review-rules-card .rules-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.review-rules-card form {
  text-align: left;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 10px;
}

.review-rules-card form .agreement {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: left;
  gap: 10px;

}

.review-rules-card form .agreement input {

  flex: 0 0 20px;
  /* 0 0 means it will not change based off rest of flex items */
  /* 20px specifies base length (i.e. the flex basis property) */

}




.remember {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.disclaimer {
  text-align: center;
}

.submit {
  /* use same button styling  */
  padding: 15px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  cursor: pointer;
  margin: 20px 0 0 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: black;
}
.return {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  cursor: pointer;
  margin: 10px 0;
  font-size: 1rem;
  /* width: 50%; */
  align-self: center;

}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px;
  cursor: pointer;
  
}

.emphasis {
  font-weight: 700;
  text-decoration: underline;
}
