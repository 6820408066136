.course-page {
  padding: 10vw;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.course-page h1 {
  font-size: 2rem;
  font-weight: 600;
}

.course-page .reviews h1 {
  margin-bottom: 20px;
}

.course-page hr {
  border: 2.5px solid var(--primary-color);
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;
}

.course-page .details {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 2rem;
  gap: 10px;
}

.course-page .details p {
  background-color: var(--tertiary-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primary-color);
  text-align: left;
  width: fit-content;
}

.course-page .dropdowns {
  display: flex;
  flex-direction: column;
  /* border: 1px solid var(--primary-color); */
  margin-top: 30px;
  gap: 20px;
}

.course-page .dropdowns .desc,
.course-page .dropdowns .review-form {
  background-color: var(--tertiary-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  border: none;
  border-radius: 5px;
  padding: 20px 20px;
  font-size: 1rem;

  text-align: left;
  max-height: 62px;

  /* Controls the hover in and out*/
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1), max-height 0.5s ease-in-out;

  overflow: hidden;
}

.course-page .dropdowns h2 {
  cursor: pointer;
}

.course-page .dropdowns .desc > * {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

.course-page .dropdowns > div:hover {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
  transform: scale(1.025);
}

.course-page .dropdowns .animate-in {
  max-height: 2000px;
  overflow-y: scroll;
  transition: all 0.5s ease-in-out;
}

.course-page .dropdowns .review-form .review-form-hidden {
  opacity: 0;
  transition: opacity 0.1s ease-in;
}

.review-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0;
}

.course-page .dropdowns .review-form #title {
  font-size: 1.25rem;

  border: 1px solid #e0e0e0;

  border-radius: 5px;
  padding: 10px;
  width: 90%;
}

.course-page .dropdowns .review-form #title::placeholder {
  font-weight: 400;
}

.course-page .dropdowns .review-form .sliders {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  gap: 10px;
}

.sliders .slider {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  gap: 7.5px;
}

.sliders .slider .label-desc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sliders .slider .label-desc p {
  font-size: 0.8rem;
}

.sliders .slider input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d352;
  outline: none;
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.sliders .slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;
}

.review-form form .all-text-areas {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.review-form form textarea {
  font-size: 1.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 150px;
  resize: none;
}

.review-final-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.review-final-row .buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;

}

.review-final-row .buttons button {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  flex: 1;

  padding: 15px 10px;

  /* padding: 15px 100px; */
  font-size: 1rem;
  font-weight: 600;
  color: black;

  transition: background-color 0.2s ease-in-out;
}

.review-final-row .buttons button:hover {
  transition: background-color 0.2s ease-in-out;
  background-color: var(--primary-color);
  color: var(--tertiary-color);
}

.char-count {
  font-size: 0.75rem;
  text-align: right;
  color: #a0a0a0;
}

.text-area-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.course-page .reviews {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}

@media screen and (max-width: 730px) {
  .course-page .dropdowns .review-form #title {
    font-size: 1rem;

    border: 1px solid #e0e0e0;

    border-radius: 5px;
    padding: 10px;
    width: 90%;
  }

  .sliders .slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }

  .review-form form textarea {
    font-size: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 200px;
    resize: none;
  }

}
