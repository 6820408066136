.review-dashboard {

  display: flex;
  flex-direction: column;
  gap: 20px;

  text-align: left;
  list-style: none;
  width: 100%;
}
