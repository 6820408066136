.course-card {

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 25px;

  border: none;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

}

.course-card:hover{
  background-color: var(--select-color);
}



.course-card .card-desc {
  max-height: 50px;
  overflow-y: scroll;
}


@media screen and (max-width: 700px){
  .course-card h2 {
    font-size: 1.25rem;
  }

  .course-card .details p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 500px){
  .course-card {
    padding: 20px;
  }

  .course-card h2 {
    font-size: 1.5rem;
  }
}

