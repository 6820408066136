:root {
  /* set colors */
  --primary-color: #104484;
  --secondary-color: #1f1f1f;
  --tertiary-color: #ffffff;
  --select-color: #fafafa;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 350px;
  overflow-x: scroll;
}

.hero {
  /* border: 1px solid #ccc; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 20px;

  background-image: url("Assets/MA20220510-014.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* FOR MENU */
.show {
  transform: translateX(0);
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
}

.hide {
  /* slide to the right */
  transform: translateX(100%);
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.8s;
}

@media screen and (max-width: 710px) {
  .search-filters ul li {
    display: none;
  }
}
