hr {

  padding: 0;
  margin: 0;
  width: 200px;
  height: 1px;
  background-color: var(--tertiary-color);
  margin-right: 15%;

}

.menu {
  position: fixed;
  right: 0;
  top: 0;

  text-align: right;
  /* padding: 50px; */

  background-color: #104484b4;
  backdrop-filter: blur(10px);
  box-shadow: rgba(99, 99, 99, 0.2) 8px 2px 8px 10px;
  height: 100%;
  width: 280px;
  z-index: 2;
}

.menu .close {

  position: relative;

  margin-bottom: 20px;
  margin-right: 15%;
  cursor: pointer;

  /* do not highliht image when pressed */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */


}

.menu-links {
  list-style: none;

  padding: 50px 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;

  gap: 5px;
}



.menu-links li, .menu-links li button {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 1.5rem;

  margin-right: 15%;

  border: none;
  border-radius: 5px;
  padding: 10px;

  /* disables text highlighting */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
}

.menu-links li button {
  margin: 0;
  padding: 0;
}

.menu-links li:hover {
  /* transition to a darker opacity */

  background-color: #dcdcdc5c;
  transition: all ease-in-out 0.2s;
}

.menu-links a {
  text-decoration: none;
  color: var(--secondary-color);
}

@media screen and (max-height: 700px){

  /* how to select all li but NOT hr */
  .menu-links li {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .menu-links li, .menu-links li button {
    font-size: 1.2rem;
  }

  .menu-links {

    overflow-y: scroll;
  }
}


